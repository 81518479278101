/*!
 * fullPage 3.0.9
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
	margin: 0;
	padding: 0;
	overflow: hidden;

	/*Avoid flicker on slides transitions for mobile phones #336 */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fp-section {
	position: relative;
	-webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
	-moz-box-sizing: border-box; /* <=28 */
	box-sizing: border-box;
}
.fp-slide {
	float: left;
}
.fp-slide,
.fp-slidesContainer {
	height: 100%;
	display: block;
}
.fp-slides {
	z-index: 1;
	height: 100%;
	overflow: hidden;
	position: relative;
	-webkit-transition: all 0.3s ease-out; /* Safari<=6 Android<=4.3 */
	transition: all 0.3s ease-out;
}
.fp-section.fp-table,
.fp-slide.fp-table {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.fp-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}
.fp-slidesContainer {
	float: left;
	position: relative;
}

.fp-controlArrow.fp-prev {
	display: inline-block !important;
	content: url("../assets/images/ic-arrow-left.svg") !important;
	background-size: 48px!important;
	background-repeat: no-repeat!important;
	background-position: right center!important;
	width: 48px!important;
	height: 48px!important;
	left: 0;
	border-width: 0 !important;
	border-color: 0 !important;
}
.fp-controlArrow.fp-next {
	display: inline-block!important;
	content: url("../assets/images/ic-arrow-right.svg")!important;
	background-size: 48px!important;
	background-repeat: no-repeat!important;
	background-position: right center!important;
	width: 48px!important;
	height: 48px!important;
	right: 0!important;

	border-width: 0 !important;
	border-color: 0 !important;
}
.fp-scrollable {
	overflow: hidden;
	position: relative;
}
.fp-scroller {
	overflow: hidden;
}
.iScrollIndicator {
	border: 0 !important;
}
.fp-notransition {
	-webkit-transition: none !important;
	transition: none !important;
}
#fp-nav {
	position: fixed;
	z-index: 1000;
	top: 50%;
	opacity: 1;
	transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav ul li a:last-child {
	display: none;
}

#fp-nav.fp-right {
	right: 17px;
}
#fp-nav.fp-left {
	left: 17px;
}
.fp-slidesNav {
	position: absolute;
	text-align: center;
	z-index: 4;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	left: 0 !important;
	right: 0;
	margin: 0 auto !important;
	padding-bottom: 3%;
}
.fp-slidesNav.fp-bottom {
	bottom: 17px;
}
.fp-slidesNav.fp-top {
	top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
	margin: 0;
	padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position: relative;
}
.fp-slidesNav ul li {
	display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
	height: 10px;
	width: 10px;
	margin: -2px 0 0 -2px;
	border-radius: 100px;
	background-color: #4e7cff;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	border-radius: 100px;
	position: absolute;
	z-index: 1;
	height: 10px;
	width: 10px;
	border: 0;
	background-color: #ccc;
	margin: -2px 0 0 -2px;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
	width: 10px;
	height: 10px;
	margin: -5px 0px 0px -5px;
}
#fp-nav ul li .fp-tooltip {
	position: absolute;
	top: -2px;
	color: #fff;
	font-size: 14px;
	font-family: arial, helvetica, sans-serif;
	white-space: nowrap;
	max-width: 220px;
	overflow: hidden;
	display: block;
	opacity: 0;
	width: 0;
	cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
	-webkit-transition: opacity 0.2s ease-in;
	transition: opacity 0.2s ease-in;
	width: auto;
	opacity: 1;
}
#fp-nav ul li .fp-tooltip.fp-right {
	right: 20px;
}
#fp-nav ul li .fp-tooltip.fp-left {
	left: 20px;
}

#fp-nav ul li {
	margin: 16px;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
	height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
	height: auto !important;
}

/*Only display content to screen readers*/
.fp-sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

/* 
=============================================================
Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
=============================================================
*/

@media screen and (max-width: 991px) {
	.fp-slidesNav {
		padding-bottom: 8%;
	}
}

@media screen and (max-width: 576px) {
	.fp-slidesNav {
		display: none;
	}

	.fp-controlArrow {
		top: 65%;
	}
}
