@charset "UTF-8";

/* Gmarket Sans Font */
@font-face {
	font-family: "GmarketSansLight";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "GmarketSansBold";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

/* Noto Sans KR Font */
@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");

/* Roboto Condensed */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

/* All Device 
모든 해상도를 위한 공통 코드를 작성한다. 모든 해상도에서 이 코드가 실행됨. 
1920~ 1199px
*/

/* Content */

.wrapper {
	margin-top: 80px;
}

.content {
	width: 100%;
	position: relative;
}

.title_wrap {
	width: 100%;
	background-color: #f9fafb;
	/* padding-bottom: 68px; */
}

.title_faq {
	max-width: 900px;
	margin: 0 auto;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1.5px;
	text-align: center;
	color: #3d3f42;
	padding-top: 60px;
}

.text_faq {
	max-width: 900px;
	margin: 0 auto;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1px;
	text-align: center;
	color: #3d3f42;
	margin-top: 13px;
}

.search_wrap {
	margin: 0 auto;
	width: 100%;
	max-width: 685px;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #eaeaea;
	margin-top: 78px;
	position: relative;
	padding: 14px 0;
	text-align: left;
}

.ic_search {
	width: 16px;
	height: 16px;
	position: absolute;
	left: 20px;
	top: 14px;
}

.search_input {
	display: block;
	box-sizing: border-box;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #3d3f42;
	margin-left: 52px;
	border: none;
	width: 90%;
	box-sizing: border-box;
	background-color: transparent;
}

.search_input::placeholder {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #cccccc;
}

input[type="text"] {
	padding: 0;
	height: inherit;
}

.faq_cont {
	width: 100%;
	padding-bottom: 10px;
}

.faq_list {
	width: 100%;
	background-color: #f9fafb;
	margin: 0 auto;
	padding-bottom: 22px;
	border-bottom: 1px solid #dedede;
}

.stocks_list {
	max-width: 900px;
	margin: 0 auto;
	width: 100%;
	text-align: center;
}

.faq_select {
	display: none;
}

.stocks_list li {
	display: inline-block;
}

.list_item {
	padding: 0 20px;
	font-size: 22px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #b6b6b6;
}

.list_item.active {
	color: #3d3f42;
	font-weight: bold;
}

.stocks_list li::after {
	content: "";
	display: inline-block;
	font-size: 0;
	width: 1px;
	height: 12px;
	opacity: 0.5;
	background-color: #ccc;
}

.stocks_list li:last-child::after {
	display: none;
}

.faq_box {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	margin-top: 36px;
	margin-bottom: 40px;
	border-bottom: 1px solid #ececec;
	position: relative;
}

.qlist {
	width: 100%;
	position: relative;
}

.faq_wrap {
	width: 100%;
	position: relative;
}

.question {
	width: 100%;
	display: inline-block;
	position: relative;
	padding: 44px 50px;
	border-top: 1px solid #ececec;
	cursor: pointer;
	border-bottom: 1px solid #dedede;
}

.question:first-child {
	border-top: none;
}

.question_section {
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #9a9a9a;
}

.question_tit {
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #3d3f42;
	margin-top: 10px;
	padding-right: 96px;
}

.question_tit.active {
	position: relative;
	font-weight: bold;
	color: #4e7cff;
}

.ic_arrow_down {
	width: 46px;
	height: 46px;
	position: absolute;
	right: 45px;
	top: 50%;
	margin-top: -23px;
}

.ic_arrow_up {
	width: 46px;
	height: 46px;
	position: absolute;
	right: 45px;
	top: 50%;
	margin-top: -23px;
}

.answer {
	padding: 40px 44px 44px 44px;
	background-color: #f9fafb;
	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
}

.answer_txt {
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.63;
	letter-spacing: -0.5px;
	color: #3d3f42;
	margin-bottom: 10px;
}

.txt_md {
	font-weight: 700;
}

.txt_blue {
	color: #4e7cff;
}

.answer_txt:last-child {
	margin-bottom: 0;
}

.press_number_wrap {
	width: 100%;
	text-align: center;
	margin-top: 80px;
	margin-bottom: 100px;
}

.press_number_list {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.press_prev,
.press_next {
	display: inline-block;
	width: 24px;
	cursor: pointer;
}

.press_prev {
	margin-right: 20px;
}

.press_next {
	margin-left: 20px;
}

.press_prev a,
.press_next a {
	display: block;
	padding: 16px;
}

.press_num {
	display: inline-block;
	width: 42px;
	margin: 0 2px 0;
	cursor: pointer;
}

.press_num.on {
	background-image: url("../assets/images/ic-sub-04-circle-gray.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.num {
	display: block;
	font-family: "Roboto Condensed";
	font-size: 16px;
	font-weight: normal;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	padding: 12px 0;
}

.num.on {
	color: #249feb;
}

/* Footer */
.footer_wrap {
	width: 100%;
	background-color: #171920;
	padding: 2.98% 0 3.76% 0;
}

.footer_inner {
	width: 90.9%;
	max-width: 900px;
	margin: 0 auto;
}

.footer_name {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #bbbbbb;
}

.footer_detail {
	margin-top: 1.45%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
}

.footer_tab {
	margin-top: 2.57%;
}

.footer_tab_item {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.footer_tab_item::after {
	content: "";
	display: inline-block;
	background: url("../assets/images/line-footer-979797.svg");
	width: 1px;
	height: 10px;
	margin: 0 6px;
	margin-top: 5px;
}

.footer_tab_item:last-child::after {
	display: none;
}

/* Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 991px) {
	/* Contents */

	.title_faq {
		width: 100%;
		max-width: 770px;
		margin: 0 auto;
		font-size: 40px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
	}

	.search_wrap {
		max-width: 770px;
		margin-top: 78px;
	}

	.faq_cont {
		max-width: none;
		width: 100%;
	}

	.faq_list {
		width: 100%;
		background-color: #f9fafb;
		margin: 0 auto;
		padding-bottom: 22px;
		border-bottom: 1px solid #dedede;
	}

	.stocks_list {
		max-width: 770x;
		margin: 0 auto;
		width: 100%;
		text-align: center;
	}

	.faq_box {
		width: 100%;
		max-width: 770px;
		margin: 0 auto;
		margin-top: 22px;
		margin-bottom: 60px;
		border-bottom: 1px solid #ececec;
		position: relative;
	}

	.question {
		width: 100%;
		display: inline-block;
		position: relative;
		padding: 36px;
		border-top: 1px solid #ececec;
		cursor: pointer;
		border-bottom: 1px solid #dedede;
	}

	.question_section {
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		color: #9a9a9a;
	}

	.question_tit {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		color: #3d3f42;
		margin-top: 10px;
		padding-right: 96px;
	}

	.ic_arrow_down,
	.ic_arrow_up {
		width: 40px;
		height: 40px;
		position: absolute;
		right: 36px;
		top: 50%;
		margin-top: -20px;
	}

	.answer {
		padding: 36px;
		background-color: #f9fafb;
		border-top: 1px solid #dedede;
		border-bottom: 1px solid #dedede;
	}

	.answer_txt {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: -0.5px;
		color: #3d3f42;
		margin-bottom: 10px;
	}

	.press_number_wrap {
		margin-top: 60px;
	}
}

/* Lagre Device 대응
사용자 해상도가 768px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 768px) {
	/* Content */

	.wrapper {
		margin-top: 60px;
	}

	.title_wrap {
		/* padding-bottom: 48px; */
	}

	.title_faq {
		max-width: 580px;
		margin: 0 auto;
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
	}

	.text_faq {
		font-size: 16px;
	}

	.search_wrap {
		margin: 0 auto;
		width: 90.9%;
		max-width: 608px;
		background-color: #ffffff;
		border-radius: 4px;
		border: 1px solid #eaeaea;
		margin-top: 48px;
		position: relative;
		padding: 14px 0;
		text-align: left;
	}

	.ic_search {
		width: 16px;
		height: 16px;
		position: absolute;
		left: 20px;
		top: 14px;
	}

	.search_input {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		margin-left: 48px;
		margin-top: 0.3%;
	}

	.search_input::placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.13;
		letter-spacing: -0.5px;
	}

	.faq_cont {
		width: 100%;
	}

	.faq_list {
		width: 100%;
		text-align: center;
		padding-bottom: 30px;
	}

	.stocks_list {
		display: none;
	}

	.faq_select {
		display: inline-block;
		width: 90.9%;
		max-width: 608px;
		margin: 0 auto;
		border: 1px solid #eaeaea;
		border-radius: 4px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #3d3f42;
		padding: 16px 20px;
		background-image: url("../assets/images/ic-arrow-down.svg");
		background-repeat: no-repeat;
		background-size: 30px 30px;
		background-position: 96.5%;
		background-color: #fff;
	}

	.select::-ms-expand {
		display: none;
	}

	.faq_box {
		width: 100%;
		max-width: 608px;
		margin-top: 10px;
		margin-bottom: 40px;
	}

	.question {
		padding: 30px 36px;
	}

	.question_section {
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		color: #9a9a9a;
	}

	.question_tit {
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #3d3f42;
		margin-top: 4px;
		padding-right: 0;
	}

	.ic_arrow_down,
	.ic_arrow_up {
		display: none;
	}

	.answer {
		padding: 30px 36px;
	}

	.answer_txt {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: -0.5px;
		color: #3d3f42;
		margin-bottom: 6px;
	}

	.press_number_wrap {
		margin-top: 40px;
		margin-bottom: 60px;
	}

	.press_prev,
	.press_next {
		display: inline-block;
		width: 24px;
		vertical-align: top;
	}

	.press_prev a,
	.press_next a {
		display: block;
		padding: 16px;
	}

	.press_num {
		width: 42px;
		margin: 0 2px;
	}

	.num {
		padding: 16px 0;
	}
}

/* Medium Device 대응
사용자 해상도가 576px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 576px) {
	.title_wrap {
		/* padding-bottom: 36px; */
	}

	.title_faq {
		font-size: 24px;
		padding-top: 48px;
	}

	.text_faq {
		font-size: 14px;
	}

	.search_wrap {
		width: 83.3%;
		margin-top: 6.97%;
		padding: 12px 0;
	}

	.ic_search {
		width: 16px;
		height: 16px;
		left: 18px;
		top: 14px;
	}

	.search_input {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.5px;
		margin-left: 46px;
	}

	.search_input::placeholder {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.29;
	}

	.faq_select {
		width: 83.3%;
	}

	.faq_tab a:first-child {
		margin-right: 5.05%;
	}

	.stocks,
	.advisory {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.stocks.active,
	.advisory.active {
		font-size: 20px;
	}

	.faq_list {
		margin-top: 0;
	}

	.faq_box {
		width: 100%;
		margin-top: 32px;
		margin-bottom: 48px;
	}

	.qlist {
		width: 100%;
	}

	.question {
		padding: 20px 30px;
	}

	.question_tit {
		font-size: 13px;
	}

	.question::before {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}

	.answer {
		padding: 32px 48px;
	}

	.answer_txt {
		font-size: 12px;
		font-weight: 400;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: -0.26px;
	}

	.press_number_wrap {
		margin-top: 48px;
	}

	.press_prev,
	.press_next {
		display: inline-block;
		width: 24px;
		vertical-align: top;
		padding-top: 0;
	}

	.press_prev a,
	.press_next a {
		display: block;
	}

	.press_num {
		width: 48px;
		margin: 0 2px;
	}

	.num {
		padding: 16px 0;
	}
}
