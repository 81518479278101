@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,
body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,
em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,
hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,
ol,p,pre,q,s,samp,section,small,span,strike,strong,sub,summary,sup,table,
tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video
{margin:0;padding:0;border:0;outline:0;font-size:100%;font:inherit;
vertical-align:baseline;box-sizing:border-box}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
display:block;box-sizing:border-box}body{line-height:1}ol,ul{list-style:none}
blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{
content:'';content:none}ins{text-decoration:none}del{text-decoration:line-through}
table{border-collapse:collapse;border-spacing:0}
h1,h2,h3,h4,h5,h6,strong{font-weight:400}
a{display:inline-block;text-decoration:none;color:#000;outline:0}
span{display:inline-block}img{vertical-align:top}
a:active,a:link,a:visited{text-decoration:none}
a img{display:block}img{display:block;width:100%}
caption,legend{display:none}
button{margin:0;padding:0;border:0;font:inherit;background:0 0;box-sizing:inherit;
-webkit-box-sizing:inherit;cursor:pointer;outline:0}
em{font-style:normal}
input[type=password],input[type=text]{height:46px;padding:15px;
box-sizing:border-box;outline:0}
select{position:relative;outline:0;-webkit-appearance:none;
-moz-appearance:none;appearance:none}select::-ms-expand{display:none}
::selection{background:#6B6B6B;color:#fff;text-shadow:none}
::-moz-selection{background:#6B6B6B;color:#fff;text-shadow:none}
::-webkit-selection{background:#6B6B6B;color:#fff;text-shadow:none}

@font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 700;
    src: local('Spoqa Han Sans Bold'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 400;
    src: local('Spoqa Han Sans Regular'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 300;
    src: local('Spoqa Han Sans Light'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 100;
    src: local('Spoqa Han Sans Thin'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans CJK KR Regular'), local('Noto Sans CJK KR-Regular'), local('NotoSansCJKKR Regular');
    src: url(/assets/font/NotoSansCJKkr-Regular-subset.eot);
    src: url(/assets/font/NotoSansCJKkr-Regular-subset.eot?#iefix) format('embedded-opentype'),
         url(/assets/font/NotoSansCJKkr-Regular-subset.woff) format('woff'),
         url(/assets/font/NotoSansCJKkr-Regular-subset.otf) format('truetype');
}
/*!
 * fullPage 3.0.9
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
	margin: 0;
	padding: 0;
	overflow: hidden;

	/*Avoid flicker on slides transitions for mobile phones #336 */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fp-section {
	position: relative; /* Safari<=5 Android<=3 */ /* <=28 */
	box-sizing: border-box;
}
.fp-slide {
	float: left;
}
.fp-slide,
.fp-slidesContainer {
	height: 100%;
	display: block;
}
.fp-slides {
	z-index: 1;
	height: 100%;
	overflow: hidden;
	position: relative; /* Safari<=6 Android<=4.3 */
	transition: all 0.3s ease-out;
}
.fp-section.fp-table,
.fp-slide.fp-table {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.fp-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}
.fp-slidesContainer {
	float: left;
	position: relative;
}

.fp-controlArrow.fp-prev {
	display: inline-block !important;
	content: url(/static/media/ic-arrow-left.3b2a8937.svg) !important;
	background-size: 48px!important;
	background-repeat: no-repeat!important;
	background-position: right center!important;
	width: 48px!important;
	height: 48px!important;
	left: 0;
	border-width: 0 !important;
	border-color: 0 !important;
}
.fp-controlArrow.fp-next {
	display: inline-block!important;
	content: url(/static/media/ic-arrow-right.4ecce562.svg)!important;
	background-size: 48px!important;
	background-repeat: no-repeat!important;
	background-position: right center!important;
	width: 48px!important;
	height: 48px!important;
	right: 0!important;

	border-width: 0 !important;
	border-color: 0 !important;
}
.fp-scrollable {
	overflow: hidden;
	position: relative;
}
.fp-scroller {
	overflow: hidden;
}
.iScrollIndicator {
	border: 0 !important;
}
.fp-notransition {
	transition: none !important;
}
#fp-nav {
	position: fixed;
	z-index: 1000;
	top: 50%;
	opacity: 1;
	transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav ul li a:last-child {
	display: none;
}

#fp-nav.fp-right {
	right: 17px;
}
#fp-nav.fp-left {
	left: 17px;
}
.fp-slidesNav {
	position: absolute;
	text-align: center;
	z-index: 4;
	opacity: 1;
	transform: translate3d(0, 0, 0);
	left: 0 !important;
	right: 0;
	margin: 0 auto !important;
	padding-bottom: 3%;
}
.fp-slidesNav.fp-bottom {
	bottom: 17px;
}
.fp-slidesNav.fp-top {
	top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
	margin: 0;
	padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position: relative;
}
.fp-slidesNav ul li {
	display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
	height: 10px;
	width: 10px;
	margin: -2px 0 0 -2px;
	border-radius: 100px;
	background-color: #4e7cff;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	border-radius: 100px;
	position: absolute;
	z-index: 1;
	height: 10px;
	width: 10px;
	border: 0;
	background-color: #ccc;
	margin: -2px 0 0 -2px;
	transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
	width: 10px;
	height: 10px;
	margin: -5px 0px 0px -5px;
}
#fp-nav ul li .fp-tooltip {
	position: absolute;
	top: -2px;
	color: #fff;
	font-size: 14px;
	font-family: arial, helvetica, sans-serif;
	white-space: nowrap;
	max-width: 220px;
	overflow: hidden;
	display: block;
	opacity: 0;
	width: 0;
	cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
	transition: opacity 0.2s ease-in;
	width: auto;
	opacity: 1;
}
#fp-nav ul li .fp-tooltip.fp-right {
	right: 20px;
}
#fp-nav ul li .fp-tooltip.fp-left {
	left: 20px;
}

#fp-nav ul li {
	margin: 16px;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
	height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
	height: auto !important;
}

/*Only display content to screen readers*/
.fp-sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

/* 
=============================================================
Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
=============================================================
*/

@media screen and (max-width: 991px) {
	.fp-slidesNav {
		padding-bottom: 8%;
	}
}

@media screen and (max-width: 576px) {
	.fp-slidesNav {
		display: none;
	}

	.fp-controlArrow {
		top: 65%;
	}
}

@charset "UTF-8";

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 700;
	src: local("Spoqa Han Sans Bold"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf")
			format("truetype");
}

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 400;
	src: local("Spoqa Han Sans Regular"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf")
			format("truetype");
}

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 300;
	src: local("Spoqa Han Sans Light"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf")
			format("truetype");
}

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 100;
	src: local("Spoqa Han Sans Thin"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.ttf")
			format("truetype");
}

@font-face {
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Noto Sans CJK KR Regular"), local("Noto Sans CJK KR-Regular"),
		local("NotoSansCJKKR Regular");
	src: url(/assets/font/NotoSansCJKkr-Regular-subset.eot);
	src: url(/assets/font/NotoSansCJKkr-Regular-subset.eot?#iefix)
			format("embedded-opentype"),
		url(/assets/font/NotoSansCJKkr-Regular-subset.woff) format("woff"),
		url(/assets/font/NotoSansCJKkr-Regular-subset.otf) format("truetype");
}

/* 
=============================================================
All Device 
모든 해상도를 위한 공통 코드를 작성한다. 모든 해상도에서 이 코드가 실행됨. 
1920~ 1199px
=============================================================
*/

/* 공지사항 */

.wrapper {
	margin-top: 80px;
}

.notice_title_wrap {
	width: 100%;
	background-color: #f9fafb;
	padding-bottom: 68px;
}

.title_notice {
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1.5px;
	text-align: center;
	color: #3d3f42;
	padding-top: 60px;
	margin-top: 80px;
}

.notice_cont {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	padding-top: 80px;
}

.notice_list {
	width: 100%;
	position: relative;
	text-align: left;
}

.notice_wrap {
	width: 100%;
	position: relative;
}

.notice_wrap a {
	width: 100%;
	display: inline-block;
}

.notice_tit_wrap {
	width: 100%;
	padding: 40px 20px;
	border-bottom: 1px solid #ececec;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.notice_tit_wrap_b {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

.notice_tit_wrap:hover .notice_tit {
	color: #4e7cff;
}

.notice_num {
	width: 8%;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 20px;
	font-weight: bold;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #4e7cff;
}

.notice_tit {
	width: 70%;
	margin-left: 20px;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3d3f42;
}

.notice_date {
	width: 20%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}

.notice_num_b {
	width: 8%;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}
.notice_tit_b {
	width: 70%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}

.notice_date_b {
	width: 20%;
	float: right;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}

/* 공지사항 상세 */
.view_content {
	width: 100%;
	margin: 0 auto;
	padding: 48px 64px;
	position: relative;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.6;
	font-weight: 400;
	font-stretch: normal;
	letter-spacing: normal;
	font-size: 18px;
	font-style: normal;
	color: #3d3f42;
	border-bottom: 1px solid #cccccc;
}

.view_content_tit {
	padding: 48px 64px;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #3d3f42;
}

.view_btn_wrap {
	margin: 0 auto;
	margin-top: 48px;
	text-align: center;
	padding-bottom: 80px;
}

.btn_tit {
	display: inline-block;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
	padding: 16px 48px;
	margin: 0 auto;
	border: 1px solid #3d3f42;
	cursor: pointer;
}

.btn_tit:hover {
	color: #fff;
	background-color: #3d3f42;
}

/* Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 991px) {
	/* 공지사항 */

	.title_notice {
		font-size: 40px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
	}

	.notice_cont {
		max-width: 770px;
		padding-top: 80px;
	}

	/* 공지사항 상세 */
	.view_btn_wrap {
		margin-top: 60px;
	}

	.btn_tit {
		padding: 20px 48px;
	}
}

/* Lagre Device 대응
사용자 해상도가 768px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 768px) {
	.wrapper {
		margin-top: 60px;
	}

	/* 공지사항 */
	.title_notice {
		font-size: 32px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
		margin-top: 60px;
	}

	.notice_cont {
		width: 100%;
		max-width: 608px;
		padding-top: 80px;
	}

	.notice_tit_wrap {
		padding-top: 30px 20px;
	}

	.notice_tit_wrap_b {
		padding-top: 20px 20px;
	}

	.notice_date_b,
	.notice_date {
		display: none;
	}

	.notice_num_b,
	.notice_num {
		width: 8%;
		font-size: 16px;
	}

	.notice_tit,
	.notice_tit_b {
		width: 92%;
		font-size: 18px;
	}

	.btn_tit {
		font-size: 16px;
	}

	.view_content_tit {
		padding: 48px 20px;
	}
}

/* Medium Device 대응
사용자 해상도가 576px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 576px) {
	/* 공지사항 */
	.title_notice {
		font-size: 24px;
		padding-top: 48px;
	}

	.notice_cont {
		width: 100%;
		max-width: none;
		padding-top: 30px;
	}

	.notice_tit_wrap_b {
		display: none;
	}

	.notice_wrap:first-child {
	}

	.notice_tit_wrap {
		padding: 28px 16px;
	}

	.notice_tit_b,
	.notice_tit,
	.notice_num,
	.notice_num_b,
	.notice_date,
	.notice_date_b {
		font-size: 14px;
		line-height: 1.5;
	}

	.view_content_tit {
		font-size: 14px;
		padding: 24px 20px;
	}

	.notice_num,
	.notice_num_b {
		width: 10%;
	}

	.notice_tit {
		width: 85%;
	}

	.notice_tit_b {
		width: 85%;
		font-size: 12px;
	}

	.notice_num_b {
		font-size: 12px;
	}

	.btn_tit {
		font-size: 14px;
		padding: 14px 36px;
	}

	.view_btn_wrap {
		margin-top: 36px;
	}
}

@charset "UTF-8";

/* Gmarket Sans Font */
@font-face {
	font-family: "GmarketSansLight";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "GmarketSansBold";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

/* Noto Sans KR Font */

/* Roboto Condensed */

/* All Device 
모든 해상도를 위한 공통 코드를 작성한다. 모든 해상도에서 이 코드가 실행됨. 
1920~ 1199px
*/

/* Content */

.wrapper {
	margin-top: 80px;
}

.content {
	width: 100%;
	position: relative;
}

.title_wrap {
	width: 100%;
	background-color: #f9fafb;
	/* padding-bottom: 68px; */
}

.title_faq {
	max-width: 900px;
	margin: 0 auto;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1.5px;
	text-align: center;
	color: #3d3f42;
	padding-top: 60px;
}

.text_faq {
	max-width: 900px;
	margin: 0 auto;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1px;
	text-align: center;
	color: #3d3f42;
	margin-top: 13px;
}

.search_wrap {
	margin: 0 auto;
	width: 100%;
	max-width: 685px;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #eaeaea;
	margin-top: 78px;
	position: relative;
	padding: 14px 0;
	text-align: left;
}

.ic_search {
	width: 16px;
	height: 16px;
	position: absolute;
	left: 20px;
	top: 14px;
}

.search_input {
	display: block;
	box-sizing: border-box;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #3d3f42;
	margin-left: 52px;
	border: none;
	width: 90%;
	box-sizing: border-box;
	background-color: transparent;
}

.search_input::-webkit-input-placeholder {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #cccccc;
}

.search_input:-ms-input-placeholder {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #cccccc;
}

.search_input::-ms-input-placeholder {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #cccccc;
}

.search_input::placeholder {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #cccccc;
}

input[type="text"] {
	padding: 0;
	height: inherit;
}

.faq_cont {
	width: 100%;
	padding-bottom: 10px;
}

.faq_list {
	width: 100%;
	background-color: #f9fafb;
	margin: 0 auto;
	padding-bottom: 22px;
	border-bottom: 1px solid #dedede;
}

.stocks_list {
	max-width: 900px;
	margin: 0 auto;
	width: 100%;
	text-align: center;
}

.faq_select {
	display: none;
}

.stocks_list li {
	display: inline-block;
}

.list_item {
	padding: 0 20px;
	font-size: 22px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #b6b6b6;
}

.list_item.active {
	color: #3d3f42;
	font-weight: bold;
}

.stocks_list li::after {
	content: "";
	display: inline-block;
	font-size: 0;
	width: 1px;
	height: 12px;
	opacity: 0.5;
	background-color: #ccc;
}

.stocks_list li:last-child::after {
	display: none;
}

.faq_box {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	margin-top: 36px;
	margin-bottom: 40px;
	border-bottom: 1px solid #ececec;
	position: relative;
}

.qlist {
	width: 100%;
	position: relative;
}

.faq_wrap {
	width: 100%;
	position: relative;
}

.question {
	width: 100%;
	display: inline-block;
	position: relative;
	padding: 44px 50px;
	border-top: 1px solid #ececec;
	cursor: pointer;
	border-bottom: 1px solid #dedede;
}

.question:first-child {
	border-top: none;
}

.question_section {
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #9a9a9a;
}

.question_tit {
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #3d3f42;
	margin-top: 10px;
	padding-right: 96px;
}

.question_tit.active {
	position: relative;
	font-weight: bold;
	color: #4e7cff;
}

.ic_arrow_down {
	width: 46px;
	height: 46px;
	position: absolute;
	right: 45px;
	top: 50%;
	margin-top: -23px;
}

.ic_arrow_up {
	width: 46px;
	height: 46px;
	position: absolute;
	right: 45px;
	top: 50%;
	margin-top: -23px;
}

.answer {
	padding: 40px 44px 44px 44px;
	background-color: #f9fafb;
	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
}

.answer_txt {
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.63;
	letter-spacing: -0.5px;
	color: #3d3f42;
	margin-bottom: 10px;
}

.txt_md {
	font-weight: 700;
}

.txt_blue {
	color: #4e7cff;
}

.answer_txt:last-child {
	margin-bottom: 0;
}

.press_number_wrap {
	width: 100%;
	text-align: center;
	margin-top: 80px;
	margin-bottom: 100px;
}

.press_number_list {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.press_prev,
.press_next {
	display: inline-block;
	width: 24px;
	cursor: pointer;
}

.press_prev {
	margin-right: 20px;
}

.press_next {
	margin-left: 20px;
}

.press_prev a,
.press_next a {
	display: block;
	padding: 16px;
}

.press_num {
	display: inline-block;
	width: 42px;
	margin: 0 2px 0;
	cursor: pointer;
}

.press_num.on {
	background-image: url(/static/media/ic-sub-04-circle-gray.f141e56e.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.num {
	display: block;
	font-family: "Roboto Condensed";
	font-size: 16px;
	font-weight: normal;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	padding: 12px 0;
}

.num.on {
	color: #249feb;
}

/* Footer */
.footer_wrap {
	width: 100%;
	background-color: #171920;
	padding: 2.98% 0 3.76% 0;
}

.footer_inner {
	width: 90.9%;
	max-width: 900px;
	margin: 0 auto;
}

.footer_name {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #bbbbbb;
}

.footer_detail {
	margin-top: 1.45%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
}

.footer_tab {
	margin-top: 2.57%;
}

.footer_tab_item {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.footer_tab_item::after {
	content: "";
	display: inline-block;
	background: url(/static/media/line-footer-979797.b8737804.svg);
	width: 1px;
	height: 10px;
	margin: 0 6px;
	margin-top: 5px;
}

.footer_tab_item:last-child::after {
	display: none;
}

/* Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 991px) {
	/* Contents */

	.title_faq {
		width: 100%;
		max-width: 770px;
		margin: 0 auto;
		font-size: 40px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
	}

	.search_wrap {
		max-width: 770px;
		margin-top: 78px;
	}

	.faq_cont {
		max-width: none;
		width: 100%;
	}

	.faq_list {
		width: 100%;
		background-color: #f9fafb;
		margin: 0 auto;
		padding-bottom: 22px;
		border-bottom: 1px solid #dedede;
	}

	.stocks_list {
		max-width: 770x;
		margin: 0 auto;
		width: 100%;
		text-align: center;
	}

	.faq_box {
		width: 100%;
		max-width: 770px;
		margin: 0 auto;
		margin-top: 22px;
		margin-bottom: 60px;
		border-bottom: 1px solid #ececec;
		position: relative;
	}

	.question {
		width: 100%;
		display: inline-block;
		position: relative;
		padding: 36px;
		border-top: 1px solid #ececec;
		cursor: pointer;
		border-bottom: 1px solid #dedede;
	}

	.question_section {
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		color: #9a9a9a;
	}

	.question_tit {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		color: #3d3f42;
		margin-top: 10px;
		padding-right: 96px;
	}

	.ic_arrow_down,
	.ic_arrow_up {
		width: 40px;
		height: 40px;
		position: absolute;
		right: 36px;
		top: 50%;
		margin-top: -20px;
	}

	.answer {
		padding: 36px;
		background-color: #f9fafb;
		border-top: 1px solid #dedede;
		border-bottom: 1px solid #dedede;
	}

	.answer_txt {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: -0.5px;
		color: #3d3f42;
		margin-bottom: 10px;
	}

	.press_number_wrap {
		margin-top: 60px;
	}
}

/* Lagre Device 대응
사용자 해상도가 768px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 768px) {
	/* Content */

	.wrapper {
		margin-top: 60px;
	}

	.title_wrap {
		/* padding-bottom: 48px; */
	}

	.title_faq {
		max-width: 580px;
		margin: 0 auto;
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
	}

	.text_faq {
		font-size: 16px;
	}

	.search_wrap {
		margin: 0 auto;
		width: 90.9%;
		max-width: 608px;
		background-color: #ffffff;
		border-radius: 4px;
		border: 1px solid #eaeaea;
		margin-top: 48px;
		position: relative;
		padding: 14px 0;
		text-align: left;
	}

	.ic_search {
		width: 16px;
		height: 16px;
		position: absolute;
		left: 20px;
		top: 14px;
	}

	.search_input {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		margin-left: 48px;
		margin-top: 0.3%;
	}

	.search_input::-webkit-input-placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.13;
		letter-spacing: -0.5px;
	}

	.search_input:-ms-input-placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.13;
		letter-spacing: -0.5px;
	}

	.search_input::-ms-input-placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.13;
		letter-spacing: -0.5px;
	}

	.search_input::placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.13;
		letter-spacing: -0.5px;
	}

	.faq_cont {
		width: 100%;
	}

	.faq_list {
		width: 100%;
		text-align: center;
		padding-bottom: 30px;
	}

	.stocks_list {
		display: none;
	}

	.faq_select {
		display: inline-block;
		width: 90.9%;
		max-width: 608px;
		margin: 0 auto;
		border: 1px solid #eaeaea;
		border-radius: 4px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #3d3f42;
		padding: 16px 20px;
		background-image: url(/static/media/ic-arrow-down.6c637052.svg);
		background-repeat: no-repeat;
		background-size: 30px 30px;
		background-position: 96.5%;
		background-color: #fff;
	}

	.select::-ms-expand {
		display: none;
	}

	.faq_box {
		width: 100%;
		max-width: 608px;
		margin-top: 10px;
		margin-bottom: 40px;
	}

	.question {
		padding: 30px 36px;
	}

	.question_section {
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		color: #9a9a9a;
	}

	.question_tit {
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #3d3f42;
		margin-top: 4px;
		padding-right: 0;
	}

	.ic_arrow_down,
	.ic_arrow_up {
		display: none;
	}

	.answer {
		padding: 30px 36px;
	}

	.answer_txt {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: -0.5px;
		color: #3d3f42;
		margin-bottom: 6px;
	}

	.press_number_wrap {
		margin-top: 40px;
		margin-bottom: 60px;
	}

	.press_prev,
	.press_next {
		display: inline-block;
		width: 24px;
		vertical-align: top;
	}

	.press_prev a,
	.press_next a {
		display: block;
		padding: 16px;
	}

	.press_num {
		width: 42px;
		margin: 0 2px;
	}

	.num {
		padding: 16px 0;
	}
}

/* Medium Device 대응
사용자 해상도가 576px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 576px) {
	.title_wrap {
		/* padding-bottom: 36px; */
	}

	.title_faq {
		font-size: 24px;
		padding-top: 48px;
	}

	.text_faq {
		font-size: 14px;
	}

	.search_wrap {
		width: 83.3%;
		margin-top: 6.97%;
		padding: 12px 0;
	}

	.ic_search {
		width: 16px;
		height: 16px;
		left: 18px;
		top: 14px;
	}

	.search_input {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.5px;
		margin-left: 46px;
	}

	.search_input::-webkit-input-placeholder {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.29;
	}

	.search_input:-ms-input-placeholder {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.29;
	}

	.search_input::-ms-input-placeholder {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.29;
	}

	.search_input::placeholder {
		font-size: 16px;
		line-height: 1.29;
		letter-spacing: -0.29;
	}

	.faq_select {
		width: 83.3%;
	}

	.faq_tab a:first-child {
		margin-right: 5.05%;
	}

	.stocks,
	.advisory {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.stocks.active,
	.advisory.active {
		font-size: 20px;
	}

	.faq_list {
		margin-top: 0;
	}

	.faq_box {
		width: 100%;
		margin-top: 32px;
		margin-bottom: 48px;
	}

	.qlist {
		width: 100%;
	}

	.question {
		padding: 20px 30px;
	}

	.question_tit {
		font-size: 13px;
	}

	.question::before {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}

	.answer {
		padding: 32px 48px;
	}

	.answer_txt {
		font-size: 12px;
		font-weight: 400;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: -0.26px;
	}

	.press_number_wrap {
		margin-top: 48px;
	}

	.press_prev,
	.press_next {
		display: inline-block;
		width: 24px;
		vertical-align: top;
		padding-top: 0;
	}

	.press_prev a,
	.press_next a {
		display: block;
	}

	.press_num {
		width: 48px;
		margin: 0 2px;
	}

	.num {
		padding: 16px 0;
	}
}

@charset "UTF-8";

/* Gmarket Sans Font */
@font-face {
	font-family: "GmarketSansLight";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "GmarketSansBold";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

/* Noto Sans KR Font */

/* Roboto Condensed */

/* 
=============================================================
All Device 
모든 해상도를 위한 공통 코드를 작성한다. 모든 해상도에서 이 코드가 실행됨. 
1920~ 1199px
=============================================================
*/

/* header */
.header {
	width: 100vw;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 999;
	height: 80px;
	background-color: #6359fa;
	padding: 0px 60px;
	position: fixed;
	z-index: 1000;
}

.header_logo {
	padding: 32px 0;
}

.logo {
	width: 52px;
	height: 36px;
}

.header_nav_list {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.header_nav_item {
	margin: 0 24px;
	opacity: 0.5;
}

.header_nav_item:last-child {
	margin-right: 0;
}

.header_nav_item:hover {
	opacity: 1;
}

.header_nav_item:active {
	opacity: 1;
}

.nav_txt {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

/* contents */
html,
body {
	font-family: "Noto Sans KR", sans-serif;
	width: 100%;
	height: 100%;
	font-size: 10px;
}

.wrap {
	width: 100%;
	height: 100%;
	position: relative;
}

/* section1 */
.section1 {
	width: 100%;
	height: 100%;
	background-color: #161922;
}

.section1_inner {
	width: 100%;
	height: 100%;
	max-width: 900px;
	margin: 0 auto;
}

.section1_logo {
	height: 17.9%;
	position: relative;
}

.logo_blue {
	height: 45.45%;
	width: auto;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.section1_ko {
	width: auto;
	height: 9.18%;
	position: relative;
}

.logo_ko {
	margin: 0 auto;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: 84.4%;
}

.section1_sub {
	width: auto;
	height: 13.5%;
	position: relative;
}

.section1_sub p {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 3.2rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.32;
	letter-spacing: -1px;
	text-align: center;
	color: #ffffff;
}

.section1_phone {
	width: auto;
	height: 59.28%;
	position: relative;
	background-image: url(/static/media/img-main-large@3x.76b88c25.png);
	background-position: center bottom;
	background-size: auto 94.1%;
	background-repeat: no-repeat;
}

/* section2 */
.section2 {
	width: 100%;
	height: 100%;
	position: relative;
	background-image: linear-gradient(to right, #00b1ff 0%, #6359fa 85%),
		linear-gradient(to bottom, #161922, #161922);
}

.section2_inner {
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 900px;
	margin: 0 auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.section2_tit {
	width: 100%;
	height: 22%;
	position: relative;
}

.section2_br {
	display: none;
}

.section2_tit p {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 5rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1.15px;
	text-align: center;
	color: #ffffff;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.section2_sub {
	position: relative;
	width: 100%;
	height: 16%;
}

.section2_sub p {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 0;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 2.1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.9;
	letter-spacing: -1.05px;
	text-align: center;
	color: #ffffff;
}

.section2_image {
	width: 100%;
	position: relative;
	height: 62%;
}

.img_character {
	height: 60%;
	width: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 12%;
	margin: 0 auto;
	margin-left: auto;
	margin-right: auto;
}

.section2_txt_br {
	display: none;
}

/* section3 */
.section3 {
	width: 100vw;
	height: 100vh;
	position: relative;
	background-color: #fff;
}

.section3_inner {
	width: 100%;
	height: 100%;
	max-width: 900px;
	margin: 0 auto;
	position: relative;
}

.slide_wrap {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 5%;
}

.img_feature {
	width: 292px;
	height: 613px;
}

.section3_text {
	margin-left: 6%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
}

.section3_br {
	display: inline-block;
}

.section3_tit {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 3.4rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: -1px;
	color: #343c55;
}

.section3_txt {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 2.1rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: -1px;
	color: #878787;
	border-top: 1px solid #eaeaea;
	padding-top: 9.5%;
	margin-top: 9.5%;
}

/* section4 */
.section4 {
	width: 100%;
	height: 100%;
	position: relative;
	background-image: url(/static/media/bg-download@3x.e0b98d5b.jpg);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
}

.section4_inner {
	width: 100%;
	max-width: 900px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.section4_1 {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
}

.img_logo_download {
	width: 200px;
	height: 36px;
}

.section4_tit {
	margin-top: 2.5%;
	font-size: 6rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1px;
	color: #ffffff;
}

.section4_txt {
	margin-top: 5%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 1.8rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: -0.83px;
	color: #ffffff;
}

.btn_googleplay {
	margin-top: 10.5%;
	display: inline-block;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.btn_googleplay a {
	padding: 18px 36px;
}

.ic_google {
	width: 20px;
	height: 22px;
	display: inline-block;
}

.txt_googleplay {
	display: inline-block;
	margin-left: 9px;
	font-family: "Roboto Condensed";
	font-size: 2.4rem;
	font-weight: bold;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #444444;
}

.img_download {
	width: 373px;
	height: 581px;
}

.section4_4 {
	display: none;
}

/* footer */
.footer {
	width: 100vw;
	background-color: #000;
	padding: 60px 0 80px;
	margin: 0 auto;
}

.footer_inner {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.footer_info {
	width: 100%;
	margin-bottom: 3%;
}

.footer_name {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #bbbbbb;
}

.footer_detail {
	margin-top: 14px;
}

.footer_detail_txt {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
	margin-bottom: 2px;
}

.detail_txt {
	margin-bottom: 24px;
}

.footer_detail_txt:first-child::after {
	content: "";
	display: inline-block;
	background: url(/static/media/line-footer-666.faf98198.svg);
	width: 1px;
	height: 10px;
	margin: 0 6px;
	margin-top: 5px;
}

.footer_tab {
	margin-top: 24px;
}

.footer_tab_item {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.footer_tab_item:last-child::after {
	display: none;
}

.footer_tab_item::after {
	content: "";
	display: inline-block;
	background: url(/static/media/line-footer-979797.b8737804.svg);
	width: 1px;
	height: 10px;
	margin: 0 6px 0 12px;
	margin-top: 5px;
}

.footer_sns {
	font-size: 0;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.footer_sns_item {
	display: inline-block;
	padding: 0 4px;
}

.footer_sns_item img {
	width: 48px;
	height: 48px;
}

.footer_sns_item:first-child {
	padding-left: 0;
}

.footer_judge {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;

}

.tit_judge {
	padding-top: 4%;
	border-top: 1px solid #505050;
	width: 100%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.5;
	text-align: left;
	color: #bbb;
}

.wrap_judge {
	margin: 0 0 4%;
}

.li_judge {
	width: 100%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.8;
	letter-spacing: -0.5;
	text-align: left;
	color: #808080;
}

.footer_guidance {
	border-top: 1px solid #505050;
	padding-top: 2%;
	max-width: 900px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.btn_guidance {
	width: 25%;
	border: 1px solid #808080;
	border-radius: 8px;
	text-align: center;
}

.btn_guidance:hover, .btn_guidance:active {
	background-color: rgba(255,255,255,0.1);
}

.btn_guidance a:hover, .btn_guidance a:active {
	color: #bbbbbb;
	font-weight: normal;
}

.btn_guidance a {
	width: 100%;
	padding: 20px 16px;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #808080;
}

.text_guidance {
	width: 90%;
	margin-left: 2%;
	display: inline-block;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #808080;
}

@media screen and (max-width: 1199px) {
}

/* 
=============================================================
Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
=============================================================
*/

@media screen and (max-width: 991px) {
	/* header */
	.header_nav_item {
		margin: 0 18px;
	}

	/* contents */
	html,
	body {
		font-size: 8.5px;
	}

	/* section1 */

	.section1_inner {
		max-width: 719px;
	}

	.section1_logo {
		height: 16.6%;
	}

	.logo_blue {
		width: auto;
		height: 44.44%;
	}

	.section1_ko {
		height: 9.8%;
	}

	.logo_ko {
		height: 71.69%;
	}

	.section1_sub {
		height: 12.03%;
	}

	.section1_phone {
		height: 61.5%;
		background-image: url(/static/media/img-main-990@3x.7910f43d.png);
		background-size: auto 93.1%;
	}

	/* section2 */
	.section2_inner {
		max-width: 719px;
	}

	.section2_tit {
		height: 22.4%;
	}

	.section2_sub {
		height: 13.66%;
	}

	.section2_sub p {
		font-size: 2rem;
	}

	.section2_image {
		height: 63.9%;
	}

	.img_character {
		height: 56.38%;
		top: 20%;
	}

	/* section3 */
	.section3_inner {
		max-width: 719px;
	}

	.img_feature {
		width: 262px;
		height: auto;
	}

	/* section4 */
	.section4_inner {
		max-width: 719px;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
	}

	.section4_1 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.section4_2 {
		display: none;
	}

	.img_logo_download {
		width: 156px;
		height: auto;
	}

	.section4_tit {
		margin-top: 5%;
	}

	.section4_3 {
		margin-top: 10%;
		text-align: center;
	}

	.img_download {
		width: 278px;
		height: 434px;
		margin: 0 auto;
	}

	.section4_4 {
		display: inline-block;
		text-align: center;
	}

	.section4_txt {
		text-align: center;
	}

	.btn_googleplay {
		text-align: center;
		margin-top: 7%;
	}

	/* footer */
	.footer_inner {
		max-width: 719px;
	}

	.footer_guidance {
		max-width: 719px;
	}

	.footer_judge {
		max-width: 719px;
	}

	.tit_judge {
		font-size: 14px;
	}

	.li_judge {
		font-size: 12px;
	}

	.footer_info {
		margin-bottom: 4%;
	}


	.footer_guidance {

		padding-top: 3%;
	}

	.btn_guidance {
		width: 32%;
	}

	.btn_guidance a {
		padding: 24px 18px;
		font-size: 16px;
	}

	.text_guidance {
		width: 90%;
		margin-left: 2%;
		display: inline-block;
		font-family: "Noto Sans KR", sans-serif;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #808080;
	}
}

/* 
=============================================================
Tablet Device 대응
사용자 해상도가 768px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
=============================================================
*/

@media screen and (max-width: 768px) {
	/* header */
	.header {
		height: 60px;
		padding: 0 24px;
	}

	.header_logo {
		padding: 19px 0;
	}

	.logo {
		width: 31px;
		height: 22px;
	}

	.header_nav_item {
		margin: 0 16px;
	}

	/* contents */
	html {
		font-size: 8px;
	}

	/* section1 */
	.section1_inner {
		max-width: 576px;
	}

	.section1_logo {
		height: 14%;
	}

	.section1_ko {
		height: 10.8%;
	}

	.section1_sub {
		height: 11.63%;
	}

	.section1_phone {
		height: 63.5%;
	}

	/* section2 */

	.section2_inner {
		max-width: 576px;
	}

	.section2_br {
		display: inline-block;
	}

	.img_character {
		height: 50%;
	}

	/* section3 */
	.section3_inner {
		max-width: 576px;
	}

	.img_feature {
		width: 222px;
		height: auto;
	}

	/* section4 */
	.section4_inner {
		max-width: 576px;
	}

	.section4_3 {
		margin-top: 18%;
	}

	/* footer */

	.footer {
		padding: 80px 0;
	}
	.footer_inner {
		max-width: 576px;
		margin: 0 auto;
	}

	.footer_info {
		margin-bottom: 7%;
	}

	.footer_guidance {
		max-width: 576px;
		flex-flow: column nowrap;
		padding-top: 5%;
	}

	.footer_judge {
		max-width: 576px;
	}


	.btn_guidance {
		width: 100%;
		border: 1px solid #808080;
		border-radius: 8px;
		text-align: center;
	}

	.btn_guidance a {
		width: 100%;
		padding: 20px 16px;
		font-family: "Noto Sans KR", sans-serif;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #808080;
	}

	.text_guidance {
		padding-top: 3%;
		width: 100%;
		margin-left: 0;
		display: inline-block;
		font-family: "Noto Sans KR", sans-serif;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #808080;
	}
}

/* 
=============================================================
Tablet Device 대응
사용자 해상도가 576px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
=============================================================
*/

@media screen and (max-width: 576px) {
	html {
		font-size: 5px;
	}

	/* header */

	.header {
		height: 60px;
	}
	.header_nav_item {
		margin: 0 8px;
	}

	.nav_txt {
		font-family: "Noto Sans KR", sans-serif;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
	}

	/* contents */
	.section1_inner {
		max-width: 320px;
	}

	.section1_logo {
		height: 10%;
	}

	.logo_blue {
		height: 80%;
	}

	.section1_phone {
		height: 67.5%;
	}

	.section1_phone {
		background-image: url(/static/media/img-main-990@3x.7910f43d.png);
		background-size: 100% auto;
	}

	/* section2 */
	.section2_txt_br {
		display: inline-block;
	}

	.section2_tit {
		order: 1;
	}

	.section2_image {
		order: 2;
		height: 43.9%;
	}

	.img_character {
		height: 75%;
	}

	.section2_sub {
		order: 3;
		height: 38.06%;
	}

	.section2_sub p {
		margin-top: 12%;
		font-size: 2.75rem;
		top: 0;
		bottom: auto;
	}

	.section2_tit {
		height: 18%;
	}

	.section2_sub p {
		font-size: 2.5rem;
	}

	/* section3 */
	.section3_inner {
		max-width: 320px;
	}

	.slide_wrap {
		flex-flow: column-reverse nowrap;
		padding-bottom: 10%;
	}

	.section3_image {
		margin-top: 10%;
	}

	.img_feature {
		width: 160px;
		height: auto;
	}

	.section3_text {
		width: 100%;
		margin-left: 0;
		text-align: center;
	}

	.section3_br {
		display: none;
	}

	.section3_tit {
		width: 100%;
		margin: 0 auto;
		font-size: 5rem;
	}

	.section3_txt {
		width: 100%;
		text-align: center;
		font-size: 2.75rem;
		font-weight: normal;
		padding-top: 0;
		border-top: none;
		margin-top: 2.5%;
	}

	/* section4 */
	.section4_inner {
		max-width: 320px;
		padding-bottom: 10%;
	}

	.img_logo_download {
		width: 110px;
		height: auto;
	}

	.img_download {
		width: 160px;
		height: auto;
	}

	.section4_tit {
		margin-top: 10%;
	}

	.section4_txt {
		font-size: 2.4rem;
	}

	.section4_3 {
		margin-top: 10%;
	}

	.btn_googleplay {
		margin-top: 10%;
	}

	.btn_googleplay a {
		padding: 16px 32px;
	}

	.txt_googleplay {
		font-size: 4rem;
	}

	/* footer */
	.footer {
		padding: 80px 0;
	}

	.footer_inner {
		max-width: 320px;
		flex-flow: column nowrap;
		text-align: center;
	}

	.footer_name {
		font-size: 14px;
	}

	.footer_detail {
		margin-top: 14px;
	}

	.footer_detail_txt {
		font-size: 12px;
		margin-bottom: 4px;
	}

	.footer_tab {
		margin-top: 12px;
	}

	.footer_tab_item {
		font-size: 12px;
	}

	.footer_sns {
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 auto;
		margin-top: 20px;
	}

	.footer_info {
		margin-bottom: 0;
	}

	.footer_sns {
		margin-bottom: 10%;
	}

	.footer_judge {
		max-width: 320px;
	}

	.footer_guidance {
		max-width: 320px;
		padding-top: 6%;
	}

	.text_guidance {
		padding-top: 5%;
	}

}

.wysiwyg-color-black {
	color: #000;
}
.wysiwyg-color-black70 {
	color: #4d4d4d;
}
.wysiwyg-color-black60 {
	color: #666666;
}
.wysiwyg-color-black50 {
	color: gray;
}
.wysiwyg-color-black40 {
	color: #999999;
}
.wysiwyg-color-black30 {
	color: #b3b3b3;
}
.wysiwyg-color-black20 {
	color: #cccccc;
}
.wysiwyg-color-black10 {
	color: #e6e6e6;
}
.wysiwyg-color-red {
	color: #f00;
}
.wysiwyg-color-orange {
	color: #f90;
}
.wysiwyg-color-yellow {
	color: #ff0;
}
.wysiwyg-color-green {
	color: #0f0;
}
.wysiwyg-color-cyan {
	color: #0ff;
}
.wysiwyg-color-blue {
	color: #00f;
}
.wysiwyg-color-purple {
	color: #90f;
}
.wysiwyg-color-pink {
	color: #f0f;
}
.wysiwyg-color-red90 {
	color: #ff3333;
}
.wysiwyg-color-red80 {
	color: #ff6666;
}
.wysiwyg-color-red70 {
	color: #ff9999;
}
.wysiwyg-color-red110 {
	color: #cc0000;
}
.wysiwyg-color-red120 {
	color: #990000;
}
.wysiwyg-color-red130 {
	color: #660000;
}
.wysiwyg-color-orange90 {
	color: #ffad33;
}
.wysiwyg-color-orange80 {
	color: #ffc266;
}
.wysiwyg-color-orange70 {
	color: #ffd699;
}
.wysiwyg-color-orange110 {
	color: #cc7a00;
}
.wysiwyg-color-orange120 {
	color: #995c00;
}
.wysiwyg-color-orange130 {
	color: #663d00;
}
.wysiwyg-color-yellow90 {
	color: #ffff33;
}
.wysiwyg-color-yellow80 {
	color: #ffff66;
}
.wysiwyg-color-yellow70 {
	color: #ffff99;
}
.wysiwyg-color-yellow110 {
	color: #cccc00;
}
.wysiwyg-color-yellow120 {
	color: #999900;
}
.wysiwyg-color-yellow130 {
	color: #666600;
}
.wysiwyg-color-green90 {
	color: #33ff33;
}
.wysiwyg-color-green80 {
	color: #66ff66;
}
.wysiwyg-color-green70 {
	color: #99ff99;
}
.wysiwyg-color-green110 {
	color: #00cc00;
}
.wysiwyg-color-green120 {
	color: #009900;
}
.wysiwyg-color-green130 {
	color: #006600;
}
.wysiwyg-color-cyan90 {
	color: #33ffff;
}
.wysiwyg-color-cyan80 {
	color: #66ffff;
}
.wysiwyg-color-cyan70 {
	color: #99ffff;
}
.wysiwyg-color-cyan110 {
	color: #00cccc;
}
.wysiwyg-color-cyan120 {
	color: #009999;
}
.wysiwyg-color-cyan130 {
	color: #006666;
}
.wysiwyg-color-blue90 {
	color: #3333ff;
}
.wysiwyg-color-blue80 {
	color: #6666ff;
}
.wysiwyg-color-blue70 {
	color: #9999ff;
}
.wysiwyg-color-blue110 {
	color: #0000cc;
}
.wysiwyg-color-blue120 {
	color: #000099;
}
.wysiwyg-color-blue130 {
	color: #000066;
}
.wysiwyg-color-purple90 {
	color: #ad33ff;
}
.wysiwyg-color-purple80 {
	color: #c266ff;
}
.wysiwyg-color-purple70 {
	color: #d699ff;
}
.wysiwyg-color-purple110 {
	color: #7a00cc;
}
.wysiwyg-color-purple120 {
	color: #5c0099;
}
.wysiwyg-color-purple130 {
	color: #3d0066;
}
.wysiwyg-color-pink90 {
	color: #ff33ff;
}
.wysiwyg-color-pink80 {
	color: #ff66ff;
}
.wysiwyg-color-pink70 {
	color: #ff99ff;
}
.wysiwyg-color-pink110 {
	color: #cc00cc;
}
.wysiwyg-color-pink120 {
	color: #990099;
}
.wysiwyg-color-pink130 {
	color: #660066;
}
.wysiwyg-font-size-x-large {
	font-size: x-large;
	font-weight: 800;
}
.wysiwyg-font-size-large {
	font-size: large;
	font-weight: 600;
}
.wysiwyg-font-size-medium {
	font-size: small;
}
.wysiwyg-font-size-small {
	font-size: x-small;
}
.wysiwyg-underline {
	text-decoration: underline;
}
.wysiwyg-text-align-right {
	text-align: right;
}
.wysiwyg-text-align-center {
	text-align: center;
}
.wysiwyg-text-align-left {
	text-align: left;
}
.wysiwyg-text-align-justify {
	text-align: justify;
}
.wysiwyg-indent1 {
	padding-left: 10px;
}
[dir="rtl"] .wysiwyg-indent1 {
	padding-left: auto;
	padding-right: 10px;
}
.wysiwyg-indent2 {
	padding-left: 20px;
}
[dir="rtl"] .wysiwyg-indent2 {
	padding-left: auto;
	padding-right: 20px;
}
.wysiwyg-indent3 {
	padding-left: 30px;
}
[dir="rtl"] .wysiwyg-indent3 {
	padding-left: auto;
	padding-right: 30px;
}
.wysiwyg-indent4 {
	padding-left: 40px;
}
[dir="rtl"] .wysiwyg-indent4 {
	padding-left: auto;
	padding-right: 40px;
}
.wysiwyg-indent5 {
	padding-left: 50px;
}
[dir="rtl"] .wysiwyg-indent5 {
	padding-left: auto;
	padding-right: 50px;
}
.wysiwyg-indent6 {
	padding-left: 60px;
}
[dir="rtl"] .wysiwyg-indent6 {
	padding-left: auto;
	padding-right: 60px;
}
.wysiwyg-indent7 {
	padding-left: 70px;
}
[dir="rtl"] .wysiwyg-indent7 {
	padding-left: auto;
	padding-right: 70px;
}
.wysiwyg-indent8 {
	padding-left: 80px;
}
[dir="rtl"] .wysiwyg-indent8 {
	padding-left: auto;
	padding-right: 80px;
}
.wysiwyg-indent9 {
	padding-left: 90px;
}
[dir="rtl"] .wysiwyg-indent9 {
	padding-left: auto;
	padding-right: 90px;
}
.wysiwyg-indent10 {
	padding-left: 100px;
}
[dir="rtl"] .wysiwyg-indent10 {
	padding-left: auto;
	padding-right: 100px;
}
.wysiwyg-indent11 {
	padding-left: 110px;
}
[dir="rtl"] .wysiwyg-indent11 {
	padding-left: auto;
	padding-right: 110px;
}
.wysiwyg-indent12 {
	padding-left: 120px;
}
[dir="rtl"] .wysiwyg-indent12 {
	padding-left: auto;
	padding-right: 120px;
}
.wysiwyg-indent13 {
	padding-left: 130px;
}
[dir="rtl"] .wysiwyg-indent13 {
	padding-left: auto;
	padding-right: 130px;
}
.wysiwyg-indent14 {
	padding-left: 140px;
}
[dir="rtl"] .wysiwyg-indent14 {
	padding-left: auto;
	padding-right: 140px;
}
.wysiwyg-indent15 {
	padding-left: 150px;
}
[dir="rtl"] .wysiwyg-indent15 {
	padding-left: auto;
	padding-right: 150px;
}
.wysiwyg-indent16 {
	padding-left: 160px;
}
[dir="rtl"] .wysiwyg-indent16 {
	padding-left: auto;
	padding-right: 160px;
}
.wysiwyg-indent17 {
	padding-left: 170px;
}
[dir="rtl"] .wysiwyg-indent17 {
	padding-left: auto;
	padding-right: 170px;
}
.wysiwyg-indent18 {
	padding-left: 180px;
}
[dir="rtl"] .wysiwyg-indent18 {
	padding-left: auto;
	padding-right: 180px;
}
.wysiwyg-indent19 {
	padding-left: 190px;
}
[dir="rtl"] .wysiwyg-indent19 {
	padding-left: auto;
	padding-right: 190px;
}
.wysiwyg-indent20 {
	padding-left: 200px;
}
[dir="rtl"] .wysiwyg-indent20 {
	padding-left: auto;
	padding-right: 200px;
}



/* 
=============================================================
All Device 
모든 해상도를 위한 공통 코드를 작성한다. 모든 해상도에서 이 코드가 실행됨. 
1920~ 1199px
=============================================================
*/

/* Notice - index.html */

.notice_tit_wrap_b {
	padding: 24px 20px;
}

.notice_tit_b {
	font-size: 20px;
	padding-right: 20px;
}

.notice_date_b {
	font-size: 20px;
	padding-right: 40px;
}

.notice_tit_wrap {
	padding: 40px;
}

.notice_tit {
	margin: 0 ;
	font-size: 18px;
}

.notice_date {
	font-size: 16px;
}



/* NoticeContent */
.view_content {
	padding: 48px 40px;
	word-break: keep-all;
}

.view_content span {
	font-weight: normal;
	line-height: 1.5;
}

.btn_tit {
	font-size: 16px;
	font-weight: normal;
}



/* FAQ */
.question_section {
	font-weight: normal;
	font-size: 18px;
}

.question_tit {
	font-size: 22px;
	word-break: keep-all;
}

.answer {
	padding: 40px 50px;
	border-top: 0;
}

.answer_txt {
	font-size: 20px;
	word-break: keep-all;
	line-height: 1.4;
	margin-bottom: 12px;
}







/* Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/


@media screen and (max-width: 991px) {

	/* Notice */
	.notice_cont {
		max-width: 768px;

	}

	.notice_tit_b {
		font-size: 18px;
	}

	.notice_date_b {
		font-size: 18px;
		padding-right: 30px
	}

	.notice_tit {
		font-size: 18px;
		margin-left: 20px;
	}

	/* NoticeCont */
	.view_content {
		padding: 48px 60px;
	}

	.view_content span {
		font-size: 16px;
	}





	/* Notice */
	.faq_box {
		max-width: 768px;
	}
	
	.question {
		padding: 48px;
	}

	.answer {
		padding: 42px 48px;
	}

	.answer_txt {
		line-height: 1.5;
		margin-bottom: 16px;

	}

	
	.ic_arrow_down, .ic_arrow_up {
		margin-right: 2%;
	}



}






/* Lagre Device 대응
사용자 해상도가 768px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 768px) {
	.notice_cont {
		max-width: none;
	}

	.notice_tit_b {
		width: 100%;
		padding-right: 0;
	}

	.notice_tit_wrap {
		padding: 40px 60px;
	}

	.notice_tit {
		width: 100%;
		margin-left: 0;
	}


	/* FAQ */
	.faq_box {
		max-width: none;
		width: 100%;
	}

	.question {
		padding: 42px 10%;
	}

	.question_section {
		font-size: 16px;
	}

	.question_tit {
		font-size: 20px;
	}

	.answer {
		padding: 42px 10%;
	}

	.answer_txt {
		font-size: 18px;
	}

}




/* Medium Device 대응
사용자 해상도가 576px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 576px) {

	.notice_tit_wrap {
		padding: 40px 10%;
	}

	.notice_tit_b {
		font-size: 16px;
	}

	.view_content {
		padding: 48px 10%;
	}


	/* FAQ */

	.question {
		padding: 28px 10%;
	}

	.question_section {
		font-size: 14px;
	}

	.question_tit {
		font-size: 18px;
	}

	.answer {
		padding: 24px 10%;
	}

	.answer_txt {
		font-size: 16px;
	}
}
