@charset "UTF-8";

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 700;
	src: local("Spoqa Han Sans Bold"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf")
			format("truetype");
}

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 400;
	src: local("Spoqa Han Sans Regular"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf")
			format("truetype");
}

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 300;
	src: local("Spoqa Han Sans Light"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf")
			format("truetype");
}

@font-face {
	font-family: "Spoqa Han Sans";
	font-weight: 100;
	src: local("Spoqa Han Sans Thin"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.woff2")
			format("woff2"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.woff")
			format("woff"),
		url("https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansThin.ttf")
			format("truetype");
}

@font-face {
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Noto Sans CJK KR Regular"), local("Noto Sans CJK KR-Regular"),
		local("NotoSansCJKKR Regular");
	src: url(/assets/font/NotoSansCJKkr-Regular-subset.eot);
	src: url(/assets/font/NotoSansCJKkr-Regular-subset.eot?#iefix)
			format("embedded-opentype"),
		url(/assets/font/NotoSansCJKkr-Regular-subset.woff) format("woff"),
		url(/assets/font/NotoSansCJKkr-Regular-subset.otf) format("truetype");
}

/* 
=============================================================
All Device 
모든 해상도를 위한 공통 코드를 작성한다. 모든 해상도에서 이 코드가 실행됨. 
1920~ 1199px
=============================================================
*/

/* 공지사항 */

.wrapper {
	margin-top: 80px;
}

.notice_title_wrap {
	width: 100%;
	background-color: #f9fafb;
	padding-bottom: 68px;
}

.title_notice {
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1.5px;
	text-align: center;
	color: #3d3f42;
	padding-top: 60px;
	margin-top: 80px;
}

.notice_cont {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	padding-top: 80px;
}

.notice_list {
	width: 100%;
	position: relative;
	text-align: left;
}

.notice_wrap {
	width: 100%;
	position: relative;
}

.notice_wrap a {
	width: 100%;
	display: inline-block;
}

.notice_tit_wrap {
	width: 100%;
	padding: 40px 20px;
	border-bottom: 1px solid #ececec;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.notice_tit_wrap_b {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

.notice_tit_wrap:hover .notice_tit {
	color: #4e7cff;
}

.notice_num {
	width: 8%;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 20px;
	font-weight: bold;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #4e7cff;
}

.notice_tit {
	width: 70%;
	margin-left: 20px;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3d3f42;
}

.notice_date {
	width: 20%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}

.notice_num_b {
	width: 8%;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: condensed;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}
.notice_tit_b {
	width: 70%;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}

.notice_date_b {
	width: 20%;
	float: right;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
}

/* 공지사항 상세 */
.view_content {
	width: 100%;
	margin: 0 auto;
	padding: 48px 64px;
	position: relative;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.6;
	font-weight: 400;
	font-stretch: normal;
	letter-spacing: normal;
	font-size: 18px;
	font-style: normal;
	color: #3d3f42;
	border-bottom: 1px solid #cccccc;
}

.view_content_tit {
	padding: 48px 64px;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #3d3f42;
}

.view_btn_wrap {
	margin: 0 auto;
	margin-top: 48px;
	text-align: center;
	padding-bottom: 80px;
}

.btn_tit {
	display: inline-block;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d3f42;
	padding: 16px 48px;
	margin: 0 auto;
	border: 1px solid #3d3f42;
	cursor: pointer;
}

.btn_tit:hover {
	color: #fff;
	background-color: #3d3f42;
}

/* Tablet Device 대응
사용자 해상도가 991px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 991px) {
	/* 공지사항 */

	.title_notice {
		font-size: 40px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
	}

	.notice_cont {
		max-width: 770px;
		padding-top: 80px;
	}

	/* 공지사항 상세 */
	.view_btn_wrap {
		margin-top: 60px;
	}

	.btn_tit {
		padding: 20px 48px;
	}
}

/* Lagre Device 대응
사용자 해상도가 768px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 768px) {
	.wrapper {
		margin-top: 60px;
	}

	/* 공지사항 */
	.title_notice {
		font-size: 32px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.5px;
		text-align: center;
		color: #3d3f42;
		padding-top: 60px;
		margin-top: 60px;
	}

	.notice_cont {
		width: 100%;
		max-width: 608px;
		padding-top: 80px;
	}

	.notice_tit_wrap {
		padding-top: 30px 20px;
	}

	.notice_tit_wrap_b {
		padding-top: 20px 20px;
	}

	.notice_date_b,
	.notice_date {
		display: none;
	}

	.notice_num_b,
	.notice_num {
		width: 8%;
		font-size: 16px;
	}

	.notice_tit,
	.notice_tit_b {
		width: 92%;
		font-size: 18px;
	}

	.btn_tit {
		font-size: 16px;
	}

	.view_content_tit {
		padding: 48px 20px;
	}
}

/* Medium Device 대응
사용자 해상도가 576px 이하일 때 이 코드가 실행됨. 미디어쿼리를 지원 
*/

@media screen and (max-width: 576px) {
	/* 공지사항 */
	.title_notice {
		font-size: 24px;
		padding-top: 48px;
	}

	.notice_cont {
		width: 100%;
		max-width: none;
		padding-top: 30px;
	}

	.notice_tit_wrap_b {
		display: none;
	}

	.notice_wrap:first-child {
	}

	.notice_tit_wrap {
		padding: 28px 16px;
	}

	.notice_tit_b,
	.notice_tit,
	.notice_num,
	.notice_num_b,
	.notice_date,
	.notice_date_b {
		font-size: 14px;
		line-height: 1.5;
	}

	.view_content_tit {
		font-size: 14px;
		padding: 24px 20px;
	}

	.notice_num,
	.notice_num_b {
		width: 10%;
	}

	.notice_tit {
		width: 85%;
	}

	.notice_tit_b {
		width: 85%;
		font-size: 12px;
	}

	.notice_num_b {
		font-size: 12px;
	}

	.btn_tit {
		font-size: 14px;
		padding: 14px 36px;
	}

	.view_btn_wrap {
		margin-top: 36px;
	}
}
